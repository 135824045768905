import React, { useState, useEffect, useContext } from "react";
import createAuth0Client from "@auth0/auth0-spa-js";
import api from "./api";

// Adapted from: https://github.com/auth0/docs/blob/2f71c36d4e468b1f7321d19e6da1e985d94173fd/articles/quickstart/spa/react/_includes/_centralized_login.md

// @ts-ignore
const DEFAULT_REDIRECT_CALLBACK = (_appState) =>
  window.history.replaceState({}, document.title, window.location.pathname);

interface AuthProps {
  isAuthenticated: boolean;
  loginWithRedirect?: any;
  logout?: any;
  user?: any;
  loading?: any;
  popupOpen?: any;
  loginWithPopup?: any;
  handleRedirectCallback?: any;
  getIdTokenClaims?: any;
  getTokenSilently?: any;
  getTokenWithPopup?: any;
}

export const Auth0Context = React.createContext<AuthProps>({
  isAuthenticated: false,
});
export const useAuth0 = () => useContext<AuthProps>(Auth0Context);
export const Auth0Provider = ({
  children,
  onRedirectCallback = DEFAULT_REDIRECT_CALLBACK,
  ...initOptions
}) => {
  const [isAuthenticated, setIsAuthenticated] = useState();
  const [user, setUser] = useState();
  const [auth0Client, setAuth0] = useState();
  const [loading, setLoading] = useState(true);
  const [popupOpen, setPopupOpen] = useState(false);

  useEffect(() => {
    const initAuth0 = async () => {
      // @ts-ignore
      const auth0FromHook = await createAuth0Client(initOptions);
      setAuth0(auth0FromHook);

      if (window.location.search.includes("code=") && window.location.search.includes("state=")) {
        const { appState } = await auth0FromHook.handleRedirectCallback();
        onRedirectCallback(appState);
      }

      const isAuthenticated = await auth0FromHook.isAuthenticated();

      setIsAuthenticated(isAuthenticated);

      if (isAuthenticated) {
        const user = await auth0FromHook.getUser();
        api.setAuthToken(await auth0FromHook.getTokenSilently());
        const { isAdmin } = await api.getCurrentUser();

        setUser({ ...user, isAdmin });
      } else {
        // Auto-redirect to log in page
        auth0FromHook.loginWithRedirect();
      }

      setLoading(false);
    };
    initAuth0();
    // eslint-disable-next-line
  }, []);

  const loginWithPopup = async (params = {}) => {
    setPopupOpen(true);
    try {
      await auth0Client.loginWithPopup(params);
    } catch (error) {
      console.error(error);
    } finally {
      setPopupOpen(false);
    }
    const user = await auth0Client.getUser();
    api.setAuthToken(await auth0Client.getTokenSilently());
    const { isAdmin } = await api.getCurrentUser();

    setUser({ ...user, isAdmin });
    setIsAuthenticated(true);
  };

  const handleRedirectCallback = async () => {
    setLoading(true);
    await auth0Client.handleRedirectCallback();
    const user = await auth0Client.getUser();
    api.setAuthToken(await auth0Client.getTokenSilently());
    const { isAdmin } = await api.getCurrentUser();

    setUser({ ...user, isAdmin });

    setLoading(false);
    setIsAuthenticated(true);
  };
  return (
    <Auth0Context.Provider
      value={{
        isAuthenticated,
        user,
        loading,
        popupOpen,
        loginWithPopup,
        handleRedirectCallback,
        getIdTokenClaims: (...p) => auth0Client.getIdTokenClaims(...p),
        loginWithRedirect: (...p) => auth0Client.loginWithRedirect(...p),
        getTokenSilently: (...p) => auth0Client.getTokenSilently(...p),
        getTokenWithPopup: (...p) => auth0Client.getTokenWithPopup(...p),
        logout: (...p) => auth0Client.logout(...p),
      }}
    >
      {children}
    </Auth0Context.Provider>
  );
};
