import _ from "lodash";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import "@ant-design/compatible/assets/index.css";
import { Button, Spin, Form, Checkbox, Input, List, Modal, Popover, notification } from "antd";
import { EuroCircleOutlined } from "@ant-design/icons";
import { StyledBigButton, PageH1, PageContent } from "./components/StyledComponents";
import { taskLabelToName } from "./constants";
import DatasetViewer from "./DatasetViewer";
import { useHistory } from "react-router-dom";
import api from "./api";
import { humanFileSize } from "./utils/formatting";
import { useQuery, useScans, useProject } from "./hooks";

const DEFAULT_PRICE = 200;

const SubsectionHeader = styled.h2`
  font-family: Montserrat;
  font-size: 32px;
  line-height: 40px;
  margin-bottom: 32px;

  text-align: center;
  letter-spacing: -0.015em;

  color: rgba(0, 0, 0, 0.8);
`;

const Options = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  label {
    flex: 0 1 500px; /*  No stretching: */
    margin: 15px;
    font-size: 22px;
  }
`;

const ListElement = styled(List.Item)`
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 26px;
  display: flex;
  align-items: center;
  letter-spacing: -0.015em;
  width: 100%;

  && {
    border-bottom: none;
  }
  padding: 6px;

  color: #2f3133;
  .left {
    margin-right: 200px;
  }
  .right {
    text-align: right;
    color: #5a5a5a;
    margin-left: auto;
  }
`;

function StyledListItem(props) {
  const { children, right } = props;
  const style = props.style || {};
  return (
    <ListElement style={style}>
      <span className="left">{children}</span> <span className="right">{right}</span>{" "}
      {props.onPreview ? (
        <Button onClick={props.onPreview} shape="round" style={{ marginLeft: 12 }}>
          Preview Result
        </Button>
      ) : null}
    </ListElement>
  );
}

const LightStyledListItem = styled(StyledListItem)`
  .left {
    color: #454d55;
  }
  .right {
    color: #454d55;
  }
`;

const StyledInput = styled(Input)`
  border: 1.5px solid rgba(134, 134, 134, 0.5);
  box-sizing: border-box;
  border-radius: 6px;

  font-size: 18px;
  font-family: Montserrat;
  padding: 18px 22px;
`;

function SubsectionHelper({ className, header, children }) {
  return (
    <div className={className}>
      <SubsectionHeader>{header}</SubsectionHeader>
      <div>{children}</div>
    </div>
  );
}

const Subsection = styled(SubsectionHelper)`
  margin-bottom: 50px;
  &:not(:last-child) {
    margin-bottom: 120px;
  }
`;

const CheckboxButtonHelper = styled.label`
    margin: auto;
    padding: 30px 60px;
    display: block;
    background: #FDFDFD;

    box-shadow: ${(props) =>
      props.checked
        ? `0px 0px 4px #55C367;`
        : `0px 2px 4px rgba(0, 0, 0, 0.25), 0px 0px 4px rgba(0, 0, 0, 0.25);`}

    border-radius: 10px;
    position: relative;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    max-width: 400px;

    font-family: Montserrat;
    font-weight: 500;
    font-size: 26px;
    line-height: 39px;
    align-items: center;
    text-align: center;
    letter-spacing: -0.015em;

    color: #2E6184;
    border: 1px solid ${(props) => (props.checked ? "#55C367" : "#FFFFFF")};
    cursor: pointer;

    // Center text
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
        
      box-shadow: ${(props) =>
        props.checked
          ? `0px 0px 4px #55C367;`
          : `0px 2px 4px rgba(0, 0, 0, 0.3), 0px 0px 4px rgba(0, 0, 0, 0.3);`}
    }


    &:has(> input:checked) {
        box-sizing: border-box;
        box-shadow: 0px 0px 4px #55C367;
    }

    input:checked ~ .checkmark {
        // Circle
        position: absolute;
        top: 10px;
        right: 10px;
        background-color: #C0F7C9;
        height: 47px;
        width: 47px;
        border-radius: 47px;
    }


    input {
        display: none
    }

    input:checked ~ .checkmark::after {
      display: block;
    }

    .checkmark::after {
        // Checkmark
        content: "";
        position: absolute;
        display: none;
        left: 19px;
        top: 11px;
        width: 10px;
        height: 20px;
        border: solid #55C467;
        border-width: 0 6px 6px 0;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
   }
`;

function CheckboxButton({ children, checked = false, onChange = (_) => {} }) {
  return (
    <CheckboxButtonHelper checked={checked}>
      {children}
      <input type="checkbox" checked={checked} onChange={onChange} />
      <span className="checkmark" />
    </CheckboxButtonHelper>
  );
}

const Hint = styled.div`
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 15px;
  letter-spacing: -0.015em;

  color: rgba(89, 101, 115, 0.8);
`;

const StyledCheckbox = styled(Checkbox)`
  span {
    font-family: Montserrat;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: -0.015em;
    color: #454d55;
  }
`;

type PriceElement = {
  name: string;
  price: number | string;
};

function PriceListing({ pricingElements }: { pricingElements: PriceElement[] }) {
  if (pricingElements.length === 0) {
    return (
      <List
        style={{
          display: "flex",
          justifyContent: "center",
          margin: "32px 200px 32px 200px",
        }}
        itemLayout="horizontal"
      >
        <ListElement>Please select a task to see a price.</ListElement>
      </List>
    );
  }

  const getStyleForIndex = (idx) => {
    // The last three pricing elements are net price, VAT and total.
    // Separate these with a thin line from the task-specific prices.
    if (idx === pricingElements.length - 3) {
      return { borderTop: "1px solid #d8d8d8" };
    }
    // Make the "total" price bold
    if (idx === pricingElements.length - 1) {
      return { fontWeight: "bold" };
    }
    return {};
  };

  const formatPrice = (price) => {
    if (typeof price === "string") {
      return price;
    }
    return `${price.toFixed(2)} €`;
  };

  return (
    <List
      style={{
        display: "flex",
        justifyContent: "center",
        margin: "32px 200px 32px 200px",
      }}
      itemLayout="horizontal"
      dataSource={pricingElements}
      renderItem={(item, idx) => (
        <LightStyledListItem right={formatPrice(item.price)} style={getStyleForIndex(idx)}>
          {item.name}
        </LightStyledListItem>
      )}
    />
  );
}

function PricingFooter({ pricingElements }) {
  return (
    <div style={{ background: "#EEEEEE", padding: "30px 0" }}>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <PriceListing pricingElements={pricingElements} />

        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "left",
            }}
          >
            <StyledBigButton
              type="primary"
              htmlType="submit"
              disabled={pricingElements.length === 0}
            >
              Submit
            </StyledBigButton>

            <Hint style={{ marginTop: 10, maxWidth: 260, padding: 2 }}>
              Payment can be delayed until downloading the processed data.
            </Hint>

            <Form.Item
              name="terms_and_services"
              rules={[
                {
                  validator: (_, value) =>
                    value
                      ? Promise.resolve()
                      : Promise.reject("Please agree to the Terms of Services."),
                },
              ]}
              valuePropName="checked"
            >
              <StyledCheckbox style={{ marginTop: 36 }}>
                I have read and agree to the Terms of Services
              </StyledCheckbox>
            </Form.Item>
          </div>
        </div>
      </div>
    </div>
  );
}

function ScanListing({ scans, onPreview }: { scans: Array<any>; onPreview?: Function | null }) {
  return (
    <List
      style={{ display: "flex", justifyContent: "center" }}
      itemLayout="horizontal"
      dataSource={scans}
      renderItem={(item) => (
        <StyledListItem
          right={humanFileSize(item.size)}
          onPreview={
            onPreview &&
            (() => {
              const { projectId } = item;
              onPreview({ projectId, inputFileId: item.id });
            })
          }
        >
          {item.filename}
        </StyledListItem>
      )}
    />
  );
}

function calculatePricingElements(tasks, scans) {
  const pricingElements: PriceElement[] = [];

  for (const taskKey of Object.keys(tasks)) {
    if (tasks[taskKey]) {
      pricingElements.push({
        name: taskLabelToName[taskKey],
        price: taskKey === "custom" ? "Custom" : DEFAULT_PRICE * scans.length,
      });
    }
  }

  if (pricingElements.length > 0) {
    const netPrice = _.sum(pricingElements.map((p) => (typeof p.price === "string" ? 0 : p.price)));
    const vatPercentage = 0.19;
    const vatPrice = netPrice * vatPercentage;
    const totalPrice = netPrice + vatPrice;
    const hasCustom = !!tasks.custom;

    pricingElements.push({ name: "Net price", price: netPrice });
    pricingElements.push({
      name: `VAT (${vatPercentage * 100}%)`,
      price: vatPrice,
    });
    pricingElements.push({
      name: "Total",
      price: hasCustom ? `${totalPrice} € + Custom` : totalPrice,
    });
  }
  return pricingElements;
}

// const TaskButtonContainer = ({ taskLabel, children }) => {
//   if (taskLabel === taskLabelToName.custom) {
//     return <Tooltip title="We will contact you for a price quote.">{children}</Tooltip>;
//   }
//   return children;
// };

export function SubmitProjectScreen() {
  const [form] = Form.useForm();
  const history = useHistory();
  const query = useQuery();
  // @ts-ignore
  const projectId = query.get("projectId") || "none";

  const [pricingElements, setPricingElements] = useState<PriceElement[]>([]);
  const scans = useScans(projectId);
  const project = useProject(projectId);
  // @ts-ignore

  useEffect(() => {
    const pricingElements = calculatePricingElements(form.getFieldValue("task"), scans);
    setPricingElements(pricingElements);
  }, [form, scans]);

  useEffect(() => {
    if (project == null) {
      return;
    }
    form.setFieldsValue({
      projectName: project.name,
    });
  }, [form, project]);

  const onFinish = async (values) => {
    console.log("values", values);
    if (!project) {
      return;
    }
    try {
      const response = await api.submitProject(projectId, {
        ...(project || {}),
        ...values,
        priceInfo: pricingElements,
      });
      await notification.success({
        message: "The project was submitted successfully.",
        description: "You can see it's status in the dashboard. We will inform you about progress.",
      });
      console.log("response", response);
      history.push(`/projects`);
    } catch (ex) {
      Modal.error({
        content: ex.toString(),
      });
    }
  };

  return (
    <Spin spinning={project == null || scans.length === 0}>
      <Form
        form={form}
        onFinish={onFinish}
        initialValues={{
          terms_and_services: false,
          task: {
            automated_skull_masking: true,
            lesion_localization: false,
            custom: false,
          },
        }}
        onValuesChange={() => {
          const tasks = form.getFieldValue("task");
          const pricingElements = calculatePricingElements(tasks, scans);
          setPricingElements(pricingElements);
        }}
      >
        <PageContent>
          <PageH1>Submit Task(s) for the Uploaded Scans</PageH1>

          <div>
            <Subsection
              header={
                <span>
                  Scans
                  <Popover
                    content="Feel free to contact us for volume discounts."
                    placement="right"
                  >
                    <EuroCircleOutlined style={{ marginLeft: 12 }} />
                  </Popover>
                </span>
              }
            >
              <ScanListing scans={scans} />
            </Subsection>

            <Subsection header="Select which processing steps should be done">
              <Options>
                {Object.keys(taskLabelToName).map((taskLabel) => (
                  <Form.Item
                    name={["task", taskLabel]}
                    noStyle
                    key={taskLabel}
                    valuePropName="checked"
                  >
                    <CheckboxButton
                      onChange={(e) => {
                        if (taskLabel === "custom" && e.target.checked) {
                          notification.info({
                            message: "Custom Request",
                            description:
                              'Please enter details about your custom request into the "Other notes" input. We will contact you for a price quote.',
                          });
                        }
                      }}
                    >
                      {taskLabelToName[taskLabel]}
                    </CheckboxButton>
                  </Form.Item>
                ))}
              </Options>
            </Subsection>

            <Subsection header="Enter Details">
              <div style={{ display: "flex", justifyContent: "center" }}>
                <div>
                  <Form.Item
                    name="projectName"
                    rules={[{ required: true, message: "Please input a name for your project." }]}
                  >
                    <StyledInput size="large" style={{ width: 300 }} placeholder="Project name" />
                  </Form.Item>
                  <Form.Item
                    name="notes"
                    rules={[
                      {
                        validator: (_, value) => {
                          return !form.getFieldValue("task").custom || value != null
                            ? Promise.resolve()
                            : Promise.reject("Please describe your 'Custom' processing request.");
                        },
                      },
                    ]}
                  >
                    <StyledInput
                      as={Input.TextArea}
                      style={{ width: 900 }}
                      rows={4}
                      placeholder="Other notes (optional unless 'Custom' is selected)"
                    />
                  </Form.Item>
                </div>
              </div>
            </Subsection>
          </div>
        </PageContent>
        <PricingFooter pricingElements={pricingElements} />
      </Form>
    </Spin>
  );
}

function PaymentForm() {
  return (
    <span>
      You should have received an email with payment information. After payment, it might take one
      to two business days for the download to be activated.
    </span>
  );
}

export function ProjectDetailsView() {
  const query = useQuery();
  // @ts-ignore
  const projectId = query.get("projectId") || "none";
  const scans = useScans(projectId);
  const project = useProject(projectId);
  const [showPreviewModal, setShowPreviewModal] = useState(false);
  const [showPaymentModal, setShowPaymentModal] = useState(false);
  const [previewParams, setPreviewParams] = useState(null);

  if (project == null || scans.length === 0) {
    return <Spin spinning></Spin>;
  }

  const initiateProjectDownload = () => {
    if (project == null) {
      return;
    }
    const url = api.getDownloadAllUrl(project.id);
    console.log("url", url);
    const aTag = document.createElement("a");
    const fileName = `${project.name}.zip`;

    aTag.href = url;
    aTag.download = fileName;
    document.body.appendChild(aTag);
    aTag.click();
    window.URL.revokeObjectURL(url);
    aTag.remove();
  };

  const isFinished = Object.keys(project.tasks).every(
    (taskKey) => project.tasks[taskKey] === "finished",
  );

  const ButtonRow = isFinished ? (
    project.isPayed ? (
      <React.Fragment>
        <div>
          <StyledBigButton
            style={{
              backgroundColor: "#27AE60",
              color: "white",
              border: "none",
            }}
            onClick={initiateProjectDownload}
          >
            Download
          </StyledBigButton>

          <Hint style={{ marginTop: 10, maxWidth: 260, padding: 2 }}>
            The payment has already been done. Click here to get a receipt.
          </Hint>
        </div>
      </React.Fragment>
    ) : (
      <React.Fragment>
        <div>
          <StyledBigButton type="primary" onClick={() => setShowPaymentModal(true)}>
            Pay & Download
          </StyledBigButton>

          <Hint style={{ marginTop: 10, maxWidth: 260, padding: 2 }}>
            Before the download is initiated, you will be redirected to the payment site.
          </Hint>
        </div>
      </React.Fragment>
    )
  ) : null;

  return (
    <PageContent>
      <Modal
        visible={showPreviewModal}
        onOk={() => setShowPreviewModal(false)}
        onCancel={() => setShowPreviewModal(false)}
        width={850}
        footer={[
          <Button key="submit" type="primary" onClick={() => setShowPreviewModal(false)}>
            OK
          </Button>,
        ]}
      >
        <h1>Data Preview</h1>
        <div>
          <DatasetViewer params={previewParams} />
        </div>
      </Modal>

      <Modal
        visible={showPaymentModal}
        onOk={() => {
          setShowPaymentModal(false);
        }}
        onCancel={() => setShowPaymentModal(false)}
        footer={[
          <Button
            key="submit"
            type="primary"
            onClick={() => {
              setShowPaymentModal(false);
            }}
          >
            Pay
          </Button>,
        ]}
      >
        <PaymentForm />
      </Modal>

      <PageH1>{project.name}</PageH1>

      <div>
        <Subsection header="Scans">
          <ScanListing
            scans={scans}
            onPreview={
              isFinished
                ? (params) => {
                    setShowPreviewModal(true);
                    setPreviewParams(params);
                  }
                : null
            }
          />
        </Subsection>

        <Subsection header="Pricing">
          <PriceListing pricingElements={project.priceInfo} />
        </Subsection>

        <div
          style={{
            display: "flex",
            justifyContent: "center",
            flexWrap: "wrap",
          }}
        >
          {ButtonRow}
        </div>
      </div>
    </PageContent>
  );
}
