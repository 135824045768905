import axios from "axios";
import { notification } from "antd";
let accessToken = null;

let accessTokenWasReceived;
const accessTokenPromise = new Promise((resolve) => {
  accessTokenWasReceived = resolve;
});

axios.interceptors.response.use(
  (response) => response,
  (error) => {
    notification.error({
      message: "An unexpected error occurred.",
      description: "Please reload the page and retry.",
      duration: 30,
    });
    console.log("error", error);
    return Promise.reject(error);
  },
);

class Api {
  setAuthToken(_token) {
    accessToken = _token;
    axios.defaults.headers.common["Authorization"] = `Bearer ${accessToken}`;

    if (accessTokenWasReceived != null) {
      accessTokenWasReceived();
    }
  }

  async getCurrentUser() {
    await accessTokenPromise;
    return (await axios.get(`/api/users/current`)).data;
  }

  async getUser(userId) {
    await accessTokenPromise;
    return (await axios.get(`/api/users/${userId}`)).data;
  }

  async performAuthTest() {
    await accessTokenPromise;
    return axios.get(`/api/auth-test`);
  }

  async fetchProject(projectId) {
    await accessTokenPromise;
    return (await axios.get(`/api/projects/${projectId}`)).data;
  }

  async fetchProjects() {
    await accessTokenPromise;
    return (await axios.get("/api/projects")).data;
  }

  async fetchAllUploads(projectId) {
    await accessTokenPromise;
    return (await axios.get(`/api/projects/${projectId}/uploads`)).data;
  }

  async fetchUploads(projectId) {
    await accessTokenPromise;
    return (await axios.get(`/api/projects/${projectId}/uploads?result_category=`)).data;
  }

  async fetchUpload(projectId, fileId) {
    await accessTokenPromise;
    const uploads = await this.fetchUploads(projectId);

    return uploads.find((upload) => upload.id === fileId);
  }

  async fetchUploadsForTask(projectId, taskKey, inputFileId) {
    await accessTokenPromise;
    return (
      await axios.get(
        `/api/projects/${projectId}/uploads?result_category=${taskKey}&input_file_id=${inputFileId}`,
      )
    ).data;
  }

  async fetchUploadsForInput(projectId, inputFileId) {
    await accessTokenPromise;
    return (await axios.get(`/api/projects/${projectId}/uploads?input_file_id=${inputFileId}`))
      .data;
  }

  async updateProject(projectId, newProject) {
    await accessTokenPromise;
    return axios.put(`/api/projects/${projectId}`, newProject);
  }

  async submitProject(projectId, project) {
    await accessTokenPromise;
    return axios.post(`/api/projects/${projectId}/submit`, project);
  }

  async deleteUpload(projectId, uid) {
    await accessTokenPromise;
    return axios.delete(`/api/projects/${projectId}/uploads/${uid}`);
  }

  getAttachResultUrl(projectId, taskKey, resultForId) {
    return `/api/projects/${projectId}/${taskKey}/${resultForId}/attachResult`;
  }

  getUploadScanToProjectUrl(projectId) {
    return `/api/projects/${projectId}/upload`;
  }

  async getEmptyProjectForUpload() {
    await accessTokenPromise;
    return (await axios.post(`/api/projects/empty`)).data;
  }

  async uploadFile(file, url) {
    await accessTokenPromise;

    const formData = new FormData();
    formData.append("file", file);

    const response = await axios.post(url, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });

    return { request: response.request, data: response.data };
  }

  getDownloadAllUrl(projectId) {
    return `/api/projects/${projectId}/downloadAll`;
  }

  getFileStreamUrl(fileId, title) {
    return `/api/uploads/${fileId}/download/${title}.nii`;
  }
}

const api = new Api();

export default api;
