import React, { useState, useEffect } from "react";
import { useQuery } from "./hooks";
import api from "./api";

function loadScript(src) {
  return new Promise((resolve) => {
    const script = document.createElement("script");
    script.onload = function () {
      resolve();
    };
    script.src = src;
    document.head.prepend(script);
  });
}

function loadStyleSheet(src) {
  const fileref = document.createElement("link");
  fileref.setAttribute("rel", "stylesheet");
  fileref.setAttribute("type", "text/css");
  fileref.setAttribute("href", src);
  document.getElementsByTagName("head")[0].appendChild(fileref);
}

export default function RawDatasetViewer() {
  const [imageUrls, setImageUrls] = useState<Array<string>>([]);

  useEffect(() => {
    (async () => {
      await loadScript("https://ajax.googleapis.com/ajax/libs/jquery/1.11.3/jquery.min.js");
      // Load papaya from public folder
      await loadScript("/papaya.js");
      loadStyleSheet("/papaya.css");
    })();
  }, [imageUrls]);

  // @ts-ignore
  const query = useQuery();
  const projectId = query.get("projectId") || "";
  const inputFileId = query.get("inputFileId") || "";

  console.log("projectId, inputFileId", projectId, inputFileId);

  useEffect(() => {
    (async () => {
      const filesToRender = await api.fetchUploadsForInput(projectId, inputFileId);
      const fileIds = [inputFileId, ...filesToRender.map((f) => f.id)];
      const newImageUrls = fileIds.map(api.getFileStreamUrl);
      setImageUrls(newImageUrls);

      console.log("adding image", newImageUrls[0]);
    })();
  }, [projectId, inputFileId]);

  useEffect(() => {
    if (imageUrls.length === 0) {
      return;
    }
    const params = {};
    // @ts-ignore
    window.params = params;
    params["images"] = imageUrls;

    for (const imageUrl of params["images"].slice(1)) {
      const imageName = imageUrl.split("/").slice(-1)[0];
      params[imageName] = { lut: "Overlay (Positives)", alpha: 0.5 };
    }
    // @ts-ignore
    papaya.Container.addViewer("papayaContainer", window.params);
  }, [imageUrls]);

  return <div id="papayaContainer"></div>;
}
