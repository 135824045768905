import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import api from "./api";

export function useQuery() {
	return new URLSearchParams(useLocation().search);
}

export function useScans(projectId) {
	const [scans, setScans] = useState([]);
	useEffect(() => {
		(async () => {
			const uploads = await api.fetchUploads(projectId);
			setScans(uploads);
		})();
	}, [projectId]);
	return scans;
}

export function useProject(projectId) {
	const [projectInfo, setProjectInfo] = useState<any>(null);
	useEffect(() => {
		(async () => {
			const projectInfo = await api.fetchProject(projectId);
			setProjectInfo(projectInfo);
		})();
	}, [projectId]);

	return projectInfo;
}
