import React, { useState, useEffect } from "react";
import { Select, Modal, Spin, Tabs, Switch } from "antd";
import { UploadView } from "../upload";
import { taskLabelToName } from "../constants";
import api from "../api";

const { Option } = Select;
const { TabPane } = Tabs;

export default function AdminProjectModel(props) {
  const { project, updateProject } = props;

  const [inputFiles, setFiles] = useState<Array<any>>([]);
  const [owner, setOwner] = useState<any>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    (async () => {
      setIsLoading(true);

      const [files, owner] = await Promise.all([
        api.fetchUploads(project.id),
        api.getUser(project.userId),
      ]);
      setOwner(owner);
      setFiles(files);
      setIsLoading(false);
    })();
  }, [project.id, project.userId]);

  const handleChange = async (category, state) => {
    const newProject = {
      ...project,
      tasks: {
        ...project.tasks,
        [category]: state,
      },
    };
    updateProject(newProject);
    setIsLoading(true);
    await api.updateProject(project.id, newProject);
    setIsLoading(false);
  };

  const onChangeIsPayed = async (checked) => {
    const newProject = {
      ...project,
      isPayed: checked,
    };
    updateProject(newProject);
    setIsLoading(true);
    await api.updateProject(project.id, newProject);
    setIsLoading(false);
  };

  return (
    <Modal
      visible={project != null}
      width={1200}
      onOk={() => props.onExit()}
      onCancel={() => props.onExit()}
      title={`Input and Output for ${project.name}`}
    >
      <Spin spinning={isLoading}>
        <div style={{ marginBottom: 12 }}>
          <div>
            <b>User:</b> {owner != null ? owner.email : ""}
          </div>
          <div>
            <b>Notes:</b> {project.notes}
          </div>
          <div>
            <b>Payed:</b>{" "}
            <Switch checked={project != null && project.isPayed} onChange={onChangeIsPayed} />
          </div>
        </div>
        <Tabs defaultActiveKey="1">
          {Object.keys(project.tasks).map((taskKey) => {
            const uploadProps = {
              projectId: project.id,
              small: true,
              onNextHandler: null,
            };
            return (
              <TabPane tab={taskLabelToName[taskKey]} key={taskKey}>
                <Tabs defaultActiveKey="1" tabPosition="left">
                  {inputFiles.map((inputFile, i) => (
                    <TabPane tab={<span>{inputFile.filename}</span>} key={i}>
                      <div style={{ marginBottom: 16 }}>
                        <h3>Input File:</h3>
                        <a href={inputFile.url}>{inputFile.filename}</a>
                      </div>
                      <div>
                        <h3>Output File(s):</h3>
                        <UploadView
                          {...uploadProps}
                          getAllUploads={() =>
                            api.fetchUploadsForTask(project.id, taskKey, inputFile.id)
                          }
                          uploadFileUrl={api.getAttachResultUrl(project.id, taskKey, inputFile.id)}
                        />
                      </div>
                    </TabPane>
                  ))}
                </Tabs>
                <h3 style={{ marginTop: 16 }}>Status of {taskLabelToName[taskKey]}:</h3>
                <Select
                  defaultValue={project.tasks[taskKey]}
                  style={{ width: 120 }}
                  onChange={(value) => handleChange(taskKey, value)}
                >
                  <Option value="requested">Requested</Option>
                  <Option value="in_progress">In Progress</Option>
                  <Option value="finished">Finished</Option>
                </Select>
              </TabPane>
            );
          })}
        </Tabs>
      </Spin>
    </Modal>
  );
}
