import React, { useState } from "react";
import { BrowserRouter as Router, Switch, Route, Link, useLocation } from "react-router-dom";
import { ProjectOutlined, UserOutlined } from "@ant-design/icons";
import { Layout, Menu } from "antd";
import DashboardScreen from "./DashboardScreen";
import { ProjectDetailsView, SubmitProjectScreen } from "./ProjectDetailsScreen";
import "./App.css";
import { useAuth0 } from "./react-auth0-spa";
import styled from "styled-components";
import RawDatasetViewer from "./RawDatasetViewer";

const { SubMenu } = Menu;

const { Content, Sider } = Layout;

function AppLogoHelper({ className }) {
  const red = "#F72470";
  return (
    <div className={className}>
      stroke<span style={{ color: red }}>V</span>ision
    </div>
  );
}

const AppLogo = styled(AppLogoHelper)`
  margin: 10px 0 0 0;
  text-align: center;
  font-weight: bold;
  color: #f4f9fb;
  font-family: "Montserrat";
  font-size: 22px;
`;

function LeftBar() {
  const location = useLocation();
  const auth0Data = useAuth0();
  const { user, isAuthenticated, loginWithRedirect, logout } = auth0Data;
  const [isCollapsed] = useState(false);
  const userName = user != null ? user.nickname : "Anonymous";

  return (
    <Sider style={{ borderRight: "1px solid #e8e8e8" }}>
      <AppLogo collapsed={isCollapsed} />
      <Menu
        defaultSelectedKeys={[location.pathname]}
        mode="inline"
        style={{ borderRight: "none" }}
        theme="dark"
      >
        <Menu.Item key="/projects">
          <Link to="/projects">
            <ProjectOutlined />
            <span>Projects</span>
          </Link>
        </Menu.Item>
      </Menu>

      <div style={{ position: "absolute", bottom: 0, padding: 15 }}>
        <Menu mode="vertical" theme="dark" style={{ borderRight: "none" }} selectable={false}>
          <SubMenu icon={<UserOutlined />} title={userName}>
            {isAuthenticated ? (
              <Menu.Item onClick={() => logout({ returnTo: window.location.origin })}>
                Log Out
              </Menu.Item>
            ) : (
              <Menu.Item onClick={loginWithRedirect}>Log In</Menu.Item>
            )}
          </SubMenu>
        </Menu>
      </div>
    </Sider>
  );
}

function Page(props) {
  return (
    <Layout style={{ height: "100%" }}>
      <LeftBar />
      <Layout style={{ background: "#fff" }}>
        <Content>
          <div style={{ padding: 0, background: "#fff", height: "100%" }}>{props.children}</div>
        </Content>
        {/*<Footer style={{ textAlign: "center", background: "transparent" }}>
                  <a href="https://scalableminds.com" target="_blank" rel="noopener noreferrer">
                    scalable minds {new Date().getFullYear()}
                  </a>{" "}
                  &bull;{" "}
                  <a href="https://scalableminds.com/imprint" target="_blank" rel="noopener noreferrer">
                    Imprint
                  </a>{" "}
                  &bull;{" "}
                  <a href="https://scalableminds.com/privacy" target="_blank" rel="noopener noreferrer">
                    Privacy
                  </a>
                </Footer>*/}
      </Layout>
    </Layout>
  );
}

function App() {
  return (
    <Router>
      <Switch>
        <Route exact path="/">
          <Page>
            <DashboardScreen />
          </Page>
        </Route>
        <Route path="/projects">
          <Page>
            <DashboardScreen />
          </Page>
        </Route>
        <Route path="/project/new">
          <Page>
            <SubmitProjectScreen />
          </Page>
        </Route>
        <Route path="/project/details">
          <Page>
            <ProjectDetailsView />
          </Page>
        </Route>
        <Route path="/submit">
          <Page>
            <SubmitProjectScreen />
          </Page>
        </Route>
        <Route path="/rawviewer">
          <RawDatasetViewer />
        </Route>
      </Switch>
    </Router>
  );
}

export default App;
