import React, { useState, useEffect } from "react";
import { Spin } from "antd";

export default function DatasetViewer({ params }) {
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		const timer = setTimeout(() => {
			setLoading(false);
		}, 4000);
		return () => clearTimeout(timer);
	}, []);
	const paramString = Object.entries(params)
		.map(([key, val]) => `${key}=${val}`)
		.join("&");
	console.log("iframe url", `/rawviewer?${paramString}`);

	return (
		<Spin spinning={loading}>
			<iframe
				title="Data Viewer"
				src={`/rawviewer?${paramString}`}
				style={{ width: 800, height: 600, border: 0, margin: 10 }}
			/>
		</Spin>
	);
}
