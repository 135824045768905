import React, { useState, useEffect } from "react";
import { ClockCircleOutlined } from "@ant-design/icons";
import { Timeline, Button, Modal, Spin } from "antd";
import { useHistory } from "react-router-dom";
import {
  PageH1,
  PageContent,
  CenteringContainer,
  ProjectCard,
  Projects,
  TextBasedIcon,
} from "./components/StyledComponents";
import { taskLabelToName } from "./constants";
import { UploadView } from "./upload";
import AdminProjectModal from "./admin/admin_project_modal";
import _ from "lodash";
import api from "./api";
import { useAuth0 } from "./react-auth0-spa";

export default function DashboardScreen() {
  const { user } = useAuth0();
  if (!user) {
    return <Spin spinning />;
  }

  return <DashboardScreenHelper user={user} />;
}

function DashboardScreenHelper({ user }) {
  const [showImportModal, setShowImportModal] = useState(false);
  const [editingProjectId, setEditingProjectId] = useState<any>(null);
  const [isLoading, setIsLoading] = useState(false);
  const isAdmin = user != null ? user.isAdmin : false;

  const history = useHistory();

  const [emptyProjectId, setEmptyProjectId] = useState(null);

  useEffect(() => {
    (async () => {
      const emptyProject = await api.getEmptyProjectForUpload();
      setEmptyProjectId(emptyProject.id);
      console.log("emptyProject", emptyProject);
    })();
  }, []);

  const uploadProps = {
    getAllUploads: () => (emptyProjectId != null ? api.fetchAllUploads(emptyProjectId) : () => []),
    uploadFileUrl: emptyProjectId != null ? api.getUploadScanToProjectUrl(emptyProjectId) : null,
    onNextHandler: () => {
      history.push(`/project/new?projectId=${emptyProjectId}`);
    },
    projectId: emptyProjectId,
  };
  const uploadView = <UploadView onClick={() => {}} {...uploadProps} />;
  const [projects, setProjects] = useState<any>(null);

  const fetchProjects = async () => {
    setIsLoading(true);
    const projects = await api.fetchProjects();
    setIsLoading(false);

    console.log("projects", projects);
    setProjects(_.sortBy(projects, "name"));
  };

  useEffect(() => {
    fetchProjects();
  }, []);

  const updateProject = (newProject) => {
    const newProjects = projects.map((project) => {
      if (project.id !== newProject.id) {
        return project;
      } else {
        return newProject;
      }
    });
    setProjects(newProjects);
  };

  const noProjectsCreated = projects != null ? projects.length === 0 : false;

  return (
    <Spin spinning={isLoading}>
      {noProjectsCreated ? (
        <CenteringContainer>{uploadView}</CenteringContainer>
      ) : (
        <PageContent>
          {editingProjectId != null && (
            <AdminProjectModal
              onExit={() => {
                setEditingProjectId(null);
              }}
              updateProject={updateProject}
              project={projects.find((p) => p.id === editingProjectId)}
            />
          )}
          <Modal
            visible={showImportModal}
            onOk={() => setShowImportModal(false)}
            onCancel={() => setShowImportModal(false)}
            width={800}
            footer={null}
          >
            {uploadView}
          </Modal>

          <PageH1>Your Projects</PageH1>

          <Projects>
            {(projects || [])
              .filter((project) => project.isSubmitted)
              .map((project) => (
                <ProjectCard key={project.id}>
                  <h1>{project.name}</h1>

                  <main>
                    <Timeline>
                      {Object.keys(project.tasks).map((taskKey) => {
                        const isFinished = project.tasks[taskKey] === "finished";

                        const timeIcon = <ClockCircleOutlined style={{ fontSize: "16px" }} />;
                        const stateProps = {
                          dot: isFinished ? undefined : timeIcon,
                          color: isFinished ? "green" : undefined,
                        };

                        return (
                          <Timeline.Item {...stateProps} key={taskKey}>
                            {taskLabelToName[taskKey]}
                          </Timeline.Item>
                        );
                      })}
                    </Timeline>
                  </main>

                  <footer>
                    <span className="scan-count">2 Scans</span>

                    {isAdmin ? (
                      <Button
                        style={{ float: "right" }}
                        onClick={() => setEditingProjectId(project.id)}
                      >
                        Add Results
                      </Button>
                    ) : null}

                    <Button
                      type="primary"
                      style={{ float: "right" }}
                      onClick={() => {
                        history.push(`/project/details?projectId=${project.id}`);
                      }}
                    >
                      View Details
                    </Button>
                  </footer>
                </ProjectCard>
              ))}

            <ProjectCard centeredChildren clickable unselectable>
              <div onClick={() => setShowImportModal(true)}>
                <TextBasedIcon>+</TextBasedIcon>
                <h1>Add Project</h1>
              </div>
            </ProjectCard>
          </Projects>
        </PageContent>
      )}
    </Spin>
  );
}
