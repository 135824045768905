import React from "react";

import styled from "styled-components";
import "@ant-design/compatible/assets/index.css";
import { Button } from "antd";

export const StyledBigButton = styled(Button)`
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 30px;
  line-height: 37px;
  fontsize: 28px;
  border-radius: 12px;

  min-width: 260px;
  height: 90px;
  padding: 0 30px;
`;

export const PageH1 = styled.h1`
  font-family: Montserrat;
  font-size: 48px;
  line-height: 72px;
  /* identical to box height, or 150% */

  text-align: center;
  letter-spacing: -0.015em;
  margin-bottom: 78px;
  margin-top: 52px;

  color: #000000;
`;

export const PageContent = styled.div`
  margin: auto;
  padding: 20px;
  max-width: 1200px;
`;

export const CenteringContainer = styled.div`
  display: grid;
  place-items: center;
  height: 100%;
`;

export const TextBasedIcon = styled.div`
  line-height: 70px;
  text-align: center;
  color: #3796ed;
  font-size: 150px;
`;

function ProjectCardHelper(props) {
  return (
    <div className="ProjectCard" style={{ display: "inline-block" }}>
      <div className={props.className}>{props.children}</div>
    </div>
  );
}

export const ProjectCard = styled(ProjectCardHelper)`
    font-family: Montserrat;

    margin-top: 16px;
    border-radius: 10px;
    background: #fbfbfb;
    min-width: 424px;
    min-height: 265px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);

    display: grid;
    grid-template-rows: ${(props) => (props.centeredChildren ? "inherit" : "auto 1fr auto;")};
      props.centeredChildren ? "inherit" : "auto 1fr auto;"};
    place-items: ${(props) => (props.centeredChildren ? "center" : "inherit")};
    cursor: ${(props) => (props.clickable ? "pointer" : "inherit")};

    user-select: ${(props) => (props.unselectable ? "none" : "inherit")};

    h1 {
      padding: 16px 25px 0 25px;
      font-size: 26px;
      line-height: 32px;
      letter-spacing: -0.015em;

      color: #237bcd; // #2E6184;
    }

    main {
      padding: 10px 36px 25px;

      .ant-timeline-item-head-custom {
        background: transparent;
      }

      .steps li {
        font-family: Montserrat;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 17px;
        letter-spacing: -0.015em;
        color: #5A5A5A;
        padding: 6px
      }
    }

    footer {
      height: 50px;
      font-weight: 300;
      font-size: 14px;
      line-height: 17px;
      background: #f0f0f1; // #E9E9E9;
      border-radius: 0 0 10px 10px;
      padding: 16px 25px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .scan-count {
        font-family: Montserrat;
        font-style: normal;
        font-weight: 300;
        font-size: 14px;
        line-height: 17px;
        display: flex;
        align-items: center;
        letter-spacing: -0.015em;

        color: #939393;
      }

      button {
        border-radius: 10px;
      }
    }
`;

export const Projects = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  .ProjectCard {
    flex: 0 1 460px;
    margin: 0 38px 38px 38px;
  }
`;
