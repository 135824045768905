import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Button, Spin, Upload, message } from "antd";
import { PageH1, CenteringContainer } from "./components/StyledComponents";
import { CloudUploadOutlined, InboxOutlined } from "@ant-design/icons";

import { UploadChangeParam, UploadFile } from "antd/lib/upload/interface";
import api from "./api";

const { Dragger } = Upload;

const Circle = styled.div`
  width: 138px;
  height: 138px;
  background: #1790ff;
  border-radius: 138px;
  margin: auto;
`;

const UploadSubtext = styled.div`
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 29px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: -0.015em;
  margin: auto;

  color: #595959;
`;

export function UploadView(props) {
  const [isLoading, setIsLoading] = useState(false);
  const [fileList, setFileList] = useState<Array<UploadFile>>([]);
  const { getAllUploads, projectId } = props;

  useEffect(() => {
    (async () => {
      if (getAllUploads == null) {
        return;
      }
      setIsLoading(true);
      const uploads = await getAllUploads();

      setFileList(
        uploads.map((u: any) => ({
          uid: u.id,
          name: u.filename,
          fileName: u.filename,
          status: "done",
          url: u.url,
          lastModifiedDate: new Date(u.updatedAt),
        })),
      );
      setIsLoading(false);
    })();
  }, [getAllUploads]);

  function handleChange(info: UploadChangeParam) {
    const { status } = info.file;
    if (status !== "uploading") {
      console.log(info.file, info.fileList);
    }
    if (status === "done") {
      message.success(`${info.file.name} file uploaded successfully.`);
    } else if (status === "error") {
      message.error(`${info.file.name} file upload failed.`);
    }
    setFileList(
      info.fileList
        .map((f) => (f.status === "done" && f.url == null ? { ...f, url: f.response.url } : f))
        .map((f) =>
          f.status === "done" && f.uid.startsWith("rc-upload") ? { ...f, uid: f.response.id } : f,
        ),
    );
  }

  async function handleRemove(info: UploadFile) {
    if (info.status === "done") {
      try {
        await api.deleteUpload(projectId, info.uid);
        setFileList(fileList.filter((f) => f.uid !== info.uid));
        return false;
      } catch (exc) {
        console.warn("Exception was thrown", exc);
      }
    }
    return true;
  }

  let actionUrl = props.uploadFileUrl;

  const styleExtension = props.small ? {} : { border: 0, background: "transparent" };

  return (
    <Spin spinning={isLoading || actionUrl == null}>
      <div style={{ padding: props.small ? undefined : "90px 50px" }}>
        <Dragger
          name="file"
          multiple={true}
          fileList={fileList}
          customRequest={async (args) => {
            try {
              const { request, data } = await api.uploadFile(args.file, actionUrl);
              args.onSuccess(data, request);
            } catch (exc) {
              args.onError(exc);
            }
          }}
          onChange={handleChange}
          onRemove={handleRemove}
          style={{
            ...styleExtension,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
          }}
        >
          {props.small ? (
            <div>
              <p className="ant-upload-drag-icon">
                <InboxOutlined />
              </p>
              <p className="ant-upload-text">Click or drag file to this area to upload</p>
              <p className="ant-upload-hint">
                You may upload multiple result files which will all be included in the download zip.
                However, previewing the results will only work for one output file per input file
                per task.
              </p>
            </div>
          ) : (
            <DragArea />
          )}
        </Dragger>
        {fileList.length > 0 && props.onNextHandler != null ? (
          <CenteringContainer>
            <Button
              type="primary"
              style={{ marginTop: 22 }}
              disabled={fileList.some((file) => file.status !== "done")}
              size="large"
              onClick={() => props.onNextHandler()}
            >
              Next
            </Button>
          </CenteringContainer>
        ) : null}
      </div>
    </Spin>
  );
}

function DragArea(props) {
  return (
    <div>
      <div>
        <Circle>
          <CloudUploadOutlined
            style={{
              color: "white",
              margin: 34,
              fontSize: 72,
            }}
          />
        </Circle>
        <PageH1 style={{ marginBottom: 22, marginTop: 10 }}>Drop your data here</PageH1>

        <UploadSubtext style={{ maxWidth: 680 }}>
          Voxelytics supports all common image formats (tiff, png, nifti etc.) as well as zip
          archives. Note that the upload is limited to 10 GB. Reach out to us if your data is
          bigger.
        </UploadSubtext>
      </div>
    </div>
  );
}
